@import "//cdn.jsdelivr.net/npm/semantic-ui@2.4.1/dist/semantic.css";
@import url('https://fonts.googleapis.com/css2?family=Chango&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&family=Londrina+Shadow&family=Vast+Shadow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ranchers&display=swap');

$make-and-play-orange: rgb(237, 136, 123);
$make-and-play-orange-transparent: rgba($make-and-play-orange, 0);

$main-header-variable-height: min(8vw, 8vh);
$main-header-logo-height: max(min(8vw, 10vh), 45px);
$main-header-height: max($main-header-variable-height, 45px);
$main-header-spacing: max(min(12vw, 12vh), 55px);

$side-panel-width: 245px;
$collapsed-side-panel-width: 74px;

.trace-editor canvas {
  touch-action: none;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  overflow: hidden;
}

body.size-constrained {
  background-color: #DDDDDD;
}

.strikethrough {
  text-decoration: line-through;
}

@keyframes border-pulsate {
  0% {
    border-color: $make-and-play-orange;
  }

  50% {
    border-color: $make-and-play-orange-transparent;
  }

  100% {
    border-color: $make-and-play-orange;
  }
}

@keyframes background-pulsate {
  0% {
    background-color: $make-and-play-orange;
  }

  50% {
    background-color: rgb(224, 225, 226);
  }

  100% {
    background-color: $make-and-play-orange;
  }
}

.nbsp {
  white-space: nowrap;
}

.ui.dropdown .item.template-next,
.ui.dropdown.template-next,
.ui.checkbox.template-next,
.trace-profile-thumbnail img.template-next,
.ui.button.template-next {
  animation: background-pulsate 2s infinite;
}

.ui.button.basic.template-next,
.material-swatch.template-next,
.quality-level.template-next {
  border: 3px solid $make-and-play-orange;
  animation: border-pulsate 2s infinite;
}

.hints-hint-container {
  margin-top: 1em;
}

.hints-hint {
  padding: 0.25em;
  border-radius: 0.25em;
  color: white;
  animation: background-pulsate 2s infinite;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.legal {
  max-width: 1000px;
  margin: 3vh max(min(3vw, 94vw - 1000px), 3vw);
}

.legal .logo svg {
  width: 300px;
}

.action .prompt {
  padding: 0.25em 0.5em;
  font-size: 1.25em;
}

.action .terms {
  font-weight: 300;
  padding: 0.25em 0.5em;
}

.action .terms a {
  text-decoration: underline;
}

.signin-link-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5em 0;
  width: 100%;
}

.signin-link-form.password {
  display: block;
}

.signin-link-sent {
  background-color: rgba(139, 203, 223, 0.3);
  display: block;
  font-size: 1.25em;
  padding: 1em;
  border: thin solid rgba(139, 203, 223, 1);
  /* border-radius: 0.5em; */
}

.signin-link-sent a {
  text-decoration: underline;
}

.signin-link-sent .email {
  font-style: italic;
}

.signin-link-form .ui.input,
.signin-link-form .ui.button {
  margin-bottom: 0.25em;
}

.signin-link-form .ui.input {
  width: 80%;
}

.signin-link-form .ui.button {
  width: calc(20% - 0.25em);
  margin-left: 0.25em;
}

.signin-link-form.password .ui.input,
.signin-link-form.password .ui.button {
  width: 100%;
  margin-left: 0;
}

.signin-link-form.error .ui.input input {
  border: thin solid red;
}

.signin-password-form {
  display: flex;
  flex-direction: column;
  padding: 0.5em 0;
  width: 100%;
}

.signin-password-form .ui.button,
.signin-password-form .ui.input {
  margin-bottom: 0.25em;
  width: 100%;
}

.error-message {
  color: red;
  padding: 0 0.5rem;
}

.error-message a {
  text-decoration: underline;
}

.intro .intro-column.video .contact-info {
  padding: 1em 0;
  margin: 3em 0;
  text-align: center;
}

.signin {
  padding: 0.5em;
  font-weight: 300;
}

.signin a {
  font-weight: 500;
  color: $make-and-play-orange;
}

@media (pointer: fine) {

  .signin a:hover {
    color: rgba($make-and-play-orange, 0.9);
    text-decoration: underline;
  }

  .signin a:hover i.icon {
    text-decoration: none;
  }

}

.user-dropdown {
  color: $make-and-play-orange;
  user-select: none;
}

.user-dropdown .ui.dropdown .menu .item {
  color: $make-and-play-orange;
}

.user-dropdown .ui.dropdown .menu .selected.item {
  background: white;
}

.user-dropdown .signed-in-as {
  padding: 0.5em 1em;
  opacity: 0.7;
}

.user-dropdown .signed-in-as .label {
  text-transform: uppercase;
}

.user-dropdown .signed-in-as .signed-in-email {
  padding-top: 0.25em;
}

.main-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: thin solid #FEBBAC;
  background-color: white;
  z-index: 10;
}

.main-header.creation-list {
  padding-left: 5vw;
  padding-right: 5vw;
}

.logo {
  margin: 0;
  color: $make-and-play-orange;
  cursor: pointer;
}

.intro .intro-header .logo svg {
  width: auto;
  height: 100%;
}

.main-header .logo svg {
  height: $main-header-logo-height;
  width: auto;
  position: relative;
  margin-left: 1em;
}

.main-header.creation-list .logo svg {
  margin-left: 0;
}

.modal-background {
  background-image: url('/images/skybox-background.png');
  filter: blur(10px);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
}

.sign-in-container {
  background-color: white;
  box-shadow: 0 0 5px #aaaaaa;
  max-width: 800px;
  padding: 5em 5em;
  position: absolute;
  width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: blur(0);
  z-index: 10;
}

.sign-in-container .logo {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sign-in-container .logo svg {
  max-width: 500px;
}

.sign-in.sign-in.with-custom-prompt .custom-prompt {
  padding-top: 2em;
  padding-bottom: 2em;
}

.sign-in.sign-in.with-custom-prompt .custom-prompt p {
  margin-bottom: 0.5em;
}

.sign-in .mdl-textfield__label {
  top: 4px;
  font-size: 12px;
}

.thumbnail-popup.ui.wide.popup {
  text-align: right;
}

.template-note-adder .edit-action .ui.button,
.template-note .popup-trigger {
  position: absolute;
  right: -1em;
  top: -1em;
  border: thin solid white;
}

.template-note-adder .customize-note-action {
  padding-top: 1em;
}

.template-note .ui.checkbox label,
.template-note-adder .ui.checkbox label {
  color: white;
  ;
}

.template-note,
.template-note-adder {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  line-height: 1.25em;
  z-index: 1950;
}

.template-note .template-note-content,
.template-note-adder .template-note-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.template-note-info.ui.popup .prompt {
  margin-bottom: 0.5em;
}

.template-note-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.template-note-info .prompt {
  padding: 0 1em;
}

.template-note .ui.button {
  border: thin solid white;
}

.template-note.fixed,
.template-note-adder.fixed {
  position: fixed;
  bottom: 0;
  padding: 1em;
  font-size: 1.25em;
  box-shadow: 1px 1px 5px #555555;
  margin: 0 1em;
  text-align: center;
}

@media (max-width: 600px) {

  .template-note.fixed,
  .template-note-adder.fixed {
    left: $collapsed-side-panel-width;
    width: calc(100% - $collapsed-side-panel-width - 2em);
  }
}

@media (min-width: 601px) {

  .template-note.fixed,
  .template-note-adder.fixed {
    left: $side-panel-width;
    width: calc(100% - $side-panel-width - 2em);
  }
}

.template-note.inline,
.template-note-adder.inline {
  position: relative;
  padding: 1em 2em;
  font-size: 1.5vmax;
}

.template-note-adder {
  position: relative;
  cursor: pointer;
}

.template-note-adder form {
  text-align: left;
}

.template-note-adder p {
  white-space: normal;
}

.template-note-adder .edit-action .ui.button {
  box-shadow: 0px 2px 4px 0px rgb(34 36 38 / 12%), 0px 2px 10px 0px rgb(34 36 38 / 15%);
}

.template-note-form .template-character-count {
  position: absolute;
  right: 0;
  top: 0;
}

.template-note-adder .template-character-count .exceeded {
  color: red;
}

.template-note-adder a>i {
  position: relative;
  bottom: 0.15em;
}

.template-notes-editor {
  position: relative;
  background-color: white;
  text-align: left;
  height: 90vh;
  overflow-y: hidden;
}

.template-notes-editor .header {
  position: sticky;
  font-size: 2vmax;
  font-weight: bold;
  padding: 2vmax 4vmax 2vmax 4vmax;
}

.template-notes-editor-content {
  height: 100%;
  overflow-y: auto;
  max-width: 50em;
  padding: 2vmax 4vmax 15vmax 4vmax;
}

.template-notes-editor .template-notes-editor-content .label {
  font-weight: bold;
  margin-bottom: 0.25em;
}

.template-notes-editor .template-notes-editor-content .note {
  cursor: pointer;
  margin-bottom: 1em;
}

@media (pointer: fine) {

  .template-notes-editor .template-notes-editor-content .note:not(.open):hover p {
    background-color: #eeeeee;
  }

}

.template-notes-editor .ui.button.close {
  position: absolute;
  top: 1vmax;
  right: 1vmax;
  box-shadow: none;
  z-index: 12;
}

.action-popup-content.cart {
  max-width: 60vw;
  max-height: calc(90vh - $main-header-height - 1px);
  overflow: auto;
}

.action-popup-content.cart .cart-items {
  max-height: 50vh;
  overflow: auto;
}

.action-popup-content.cart .ui.dimmer {
  background-color: rgba(0, 0, 0, 0.2);
}

.action-popup-content.cart .total {
  font-size: 1.5em;
  font-weight: bold;
  margin: 1.5em auto;
  text-align: center;
}

.action-popup-content.cart .total .fine-print {
  font-size: smaller;
  font-weight: normal;
  padding-top: 0.5em;
}

.action-popup-content .cart-items-header {
  font-size: 1.15em;
  font-weight: bold;
  padding-bottom: 1em;
}

.action-popup-content .cart-items-header,
.action-popup-content .cart-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-items: flex-start;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
}

.action-popup-content .cart-item .thumbnail img,
.action-popup-content .cart-item .thumbnail video {
  width: 100%;
}

.action-popup-content .cart-items-header .thumbnail-and-description {
  width: calc(100% - 16rem);
}

.action-popup-content .cart-item .thumbnail {
  width: 20%;
}

.action-popup-content .cart-item .description {
  padding: 0 1em;
  width: calc(80% - 16rem);
}

.action-popup-content .cart-item .description .creation-name {
  font-weight: bold;
}

.action-popup-content .cart-items-header .item-actions,
.action-popup-content .cart-item .item-actions {
  width: 4rem;
}

.action-popup-content .cart-items-header .price,
.action-popup-content .cart-item .price {
  width: 6rem;
}

.action-popup-content .cart-items-header .quantity,
.action-popup-content .cart-item .quantity {
  width: 6rem;
}

.action-popup-content .cart-items-header .price,
.action-popup-content .cart-item .price,
.action-popup-content .cart-items-header .quantity,
.action-popup-content .cart-item .quantity {
  text-align: right;
  padding-right: 1em;
}

.action-popup-content .cart-item .quantity .ui.input {
  width: 90%;
}

.action-popup-content p {
  text-align: left;
  margin: 1em 24px;
  font-size: 1.25em;
}

.action-popup-content p.notification {
  color: #8BCBDF;
  border: 2px solid #8BCBDF;
  padding: 0.75em;
  border-radius: 0.25em;
  margin-bottom: 2em;
}

.action-popup-content .actions.share-it .ui.input input {
  cursor: pointer;
}

.action-popup-content .actions.share-it .alert {
  margin-top: 0.25em;
  padding: 0.25em;
  background-color: rgba($make-and-play-orange, 0.5);
  color: white;
  border-radius: 0.25em;
  text-align: center;
}

.action-popup-content .actions.creation-labels .list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.action-popup-content .actions.creation-labels .creation-label {
  margin: 0 0.5em 0.5em 0;
}

.action-popup-content .actions.creation-labels .creation-label .ui.buttons {
  border-radius: 0.25em;
}

.action-popup-content .actions.creation-labels .creation-label .ui.button {
  color: white;
  background-color: $make-and-play-orange;
  opacity: 1;
}

.action-popup-content .actions.creation-labels .creation-label .ui.button.remove-label {
  opacity: 0.8;
}

.action-popup-content .actions.creation-labels input {
  margin-bottom: 0.5em;
}

.action-popup-content .actions {
  padding: 0 1em;
}

.action-popup-content .guide {
  padding: 0.5em 0;
}


.action-popup-content .guide .actions .action {
  padding-bottom: 1em;
}

.action-popup-content .ui.buttons {
  margin: 0 1em;
}

.action-popup-content .radio.ui.checkbox {
  padding: 0 1em;
}

.action-popup-content .dimensions {
  padding-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5em;
}

.action-popup-content .dimensions i.icon {
  margin: 0 0 0 0;
}

.sign-in .mdl-button--raised.mdl-button--colored,
.action-popup-content .mdl-button--raised.mdl-button--colored {
  background-color: $make-and-play-orange;
}

.action-popup-content a {
  color: #4183C4;
}

.action-popup-content .ui.radio.checkbox {
  margin-bottom: 1em;
}

.action-popup-content .actions-info {
  max-width: 100%;
  padding: 0.5em 2em;
  background-color: #F5F5F5;
  margin-bottom: 0.5em;
}

@media (pointer: fine) {

  .action-popup-content a:hover {
    text-decoration: underline;
  }

}

.tracemaker-content .choose-image .title .logo {
  margin-bottom: 1em;
}

.tracemaker-content .choose-image .examples {
  padding: 0 1em 3em 1em;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  background-color: white;
}

.main .model-canvas {
  height: calc(100% - $main-header-spacing);
}

.main .model-view.readonly .model-canvas {
  height: calc(85% - $main-header-spacing);
}

.main .model-canvas canvas {
  width: 100%;
  height: 100%;
}

.main .choose-trace {
  width: 100%;
}

.main .twod-preview button {
  background: rgba(0, 0, 0, 0);
  border: solid 1vw #555555;
  padding: 0;
  margin: 0.5em 3em;
}

.main .twod-preview button.grayscale {
  background-color: transparent;
}

@media (pointer: fine) {

  .main .twod-preview button:hover {
    border: solid 1vw #ED8822;
  }

}

.main .twod-preview .interpretation-explanation {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 49%;
  padding: 0;
  margin: 0.5em 0.5%;
  font-size: 1.5vmax;
  line-height: 2.5vmax;
}

.main .twod-preview .interpretation-explanation .option {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 2em;
}

.main .twod-preview .interpretation-explanation .option i {
  padding: 0 1em;
  font-size: 2.5vmax;
  font-weight: bold;
  color: #ED8822;
}

.circle-picker {
  padding: 2em;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
}

.model-view {
  display: flex;
  flex-direction: row;
}

.ui.dimmer.tracemaker {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1899;
}

.ui.dimmer.tracemaker>.content,
.ui.dimmer.tracemaker>.content {
  color: #555555;
}

.tracemaker-content .nav a.cancel {
  position: absolute;
  top: 0;
  right: calc(5vw);
}

.tracemaker-content .nav a.back {
  position: absolute;
  top: 0;
  left: calc(5vw);
}

.tracemaker-content .nav {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 12;
}

.tracemaker-content {
  overflow: auto;
  height: calc(100vh - $main-header-spacing);
  width: 100%;
  background-color: white;
  position: fixed;
  top: $main-header-spacing;
  text-align: center;
}

.tracemaker-content.editor {
  left: 0;
  width: 100%;
  text-align: left;
}

.tracemaker-content.editor .ui.button.active {
  background-color: $make-and-play-orange;
  color: white;
}

.tracemaker-content.see-through {
  opacity: 0.6;
}

.tracemaker-content .header {
  padding: 0 10%;
  background-color: white;
  z-index: 11;
}

.tracemaker-content .header .title {
  padding: 0 4em 1em 4em;
  font-size: 1.5em;
  width: 100%;
}

.tracemaker-content .header .actions {
  padding: 1em 0 0.5em 0;
}

.tracemaker-content .header .title p {
  margin-bottom: 0.5em;
}

.tracemaker-content .side-actions {
  line-height: 3em;
  width: 100%;
}

.tracemaker-content .top-actions {
  position: fixed;
  top: $main-header-spacing;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.25em;
  line-height: 3em;
  z-index: 12;
  background-color: white;
  padding-left: 0.25em;
}

.tracemaker-content.editor .side-actions {
  position: fixed;
  top: $main-header-spacing;
  left: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  background-color: white;
  z-index: 11;
  padding-bottom: 3em;
}

.tracemaker-content.editor .side-actions .ui.button.color-button {
  box-sizing: border-box;
  border: 5px solid #555555;
}

.tracemaker-content.editor .side-actions .ui.button.color-button.selected {
  border-color: $make-and-play-orange;
}

@media (max-width: 600px) {

  .tracemaker-content .top-actions {
    width: min(calc(1000px + 2em), 100vw - $collapsed-side-panel-width);
  }

  .tracemaker-content .top-actions span.top-action-text {
    display: none;
  }

  .tracemaker-content.editor .side-actions {
    width: $collapsed-side-panel-width;
  }

  .tracemaker-content.editor .side-actions .prompt {
    display: none;
  }

  .tracemaker-content.editor .side-actions .setting-header {
    display: none;
  }

  .trace-content-main {
    padding-left: max(((100vw + $collapsed-side-panel-width - 1000px - 2em) / 2), $collapsed-side-panel-width);
  }
}

@media (min-width: 601px) {

  .tracemaker-content .top-actions {
    width: min(calc(1000px + 2em), 100vw - $side-panel-width);
  }

  .tracemaker-content .top-actions span.top-action-text {
    display: inline;
  }

  .tracemaker-content.editor .side-actions {
    width: $side-panel-width;
  }

  .trace-content-main {
    padding-left: max(((100vw + $side-panel-width - 1000px - 2em) / 2), $side-panel-width);
  }
}

.tracemaker-content .side-actions .spacer {
  padding: 0 1em;
}

.tracemaker-content .choose-component-kind,
.tracemaker-content .crop-image,
.tracemaker-content .choose-trace,
.tracemaker-content .choose-rotations,
.tracemaker-content .template-creation-intro {
  padding-bottom: 5em;
}

.tracemaker-content .template-creation-intro video,
.tracemaker-content .template-creation-intro img {
  width: 80%;
  max-width: 600px;
  padding-bottom: 1em;
}

.trace-content-main {
  padding-bottom: 3em;
  overflow: auto;
  height: 900px;
}

.trace-editor {
  position: absolute;
  background-color: #555555;
  top: 3.25em;
  padding: 1em;
  box-sizing: content-box;
  display: none;
}

.trace-editor .aspect-ratio-overlay-container,
.trace-editor .axes,
.trace-editor canvas {
  position: absolute;
  left: 1em;
  top: 1em;
  cursor: crosshair;
  image-rendering: pixelated;
}

.trace-editor .aspect-ratio-overlay-container,
.trace-editor .axes {
  pointer-events: none;
  opacity: 0.2;
}

.trace-editor .axes .y {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 50%;
  height: 100%;
  border-right: thin dashed #555555;
}

.trace-editor .axes .x {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 50%;
  border-bottom: thin dashed #555555;
}

.trace-editor .aspect-ratio-overlay-container .aspect-ratio-overlay {
  background-color: rgba(85, 85, 85, 0.25);
  border: thin dashed rgba(85, 85, 85, 1);
  position: relative;
  display: none;
}

.model-view .side-panel {
  width: $side-panel-width;
  position: fixed;
  top: $main-header-spacing;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: visible;
}

.model-view .side-panel .side-panel-content {
  padding: 0.5em 1em 15em 1em;
}

.model-view.settings-collapsed .side-panel {
  width: $collapsed-side-panel-width;
}

.model-view.readonly .side-panel {
  display: none;
}

.model-view.settings-collapsed .side-panel .ui.grid {
  display: none;
}

@media (max-width: 600px) {

  .model-view:not(.settings-collapsed) .canvas-setting .ui.button.optional,
  .model-view:not(.settings-collapsed) .canvas-setting.optional {
    display: none;
  }

}

.model-view .side-panel .setting {
  margin-bottom: 2em;
}

.tracemaker-content .side-actions .setting {
  margin-bottom: 1em;
  padding: 0 1em;
}

.tracemaker-content .side-actions .setting .label,
.model-view .side-panel .setting .label {
  font-weight: bold;
}

.model-view .side-panel .shape-setting-group {
  border: thin solid red;
}

.model-view .side-panel .setting.creation-name {
  padding-top: 0.5em;
}

.model-view .side-panel .setting.creation-name .ui.input>input {
  padding-left: 0em;
}

.model-view .side-panel .setting.creation-name .ui.input.saved>input {
  border-top: thin solid transparent;
  border-left: thin solid transparent;
  border-right: thin solid transparent;
  border-bottom: thin dashed #aaaaaa;
  cursor: pointer;
}

.model-view .side-panel .export {
  margin: $collapsed-side-panel-width 0;
}

.model-view .side-panel .export button {
  margin: 1em 0;
}

.model-view .side-panel-content .ui.grid.expanded {
  background-color: #EFEFFF;
  border-top: 2px solid #DFDFFF;
  margin-top: 0.25em;
}

.model-view .side-panel-content .ui.dropdown .menu>.item>img,
.model-view .side-panel-content .ui.dropdown>.text>img {
  margin-top: 0;
  margin-bottom: 0;
}

.model-view .side-panel-content .ui.icon.preview {
  background-color: #555555;
  color: #eeeeee;
  padding: 0.5em;
  width: auto;
  height: auto;
}

.material-popup-content .selection-box-points {
  padding: 0 0.25em 1em 0.25em;
}

.material-popup-content .selection-box-points .point,
.material-popup-content .selection-box-points .prompt {
  padding: 0.5em 0 0 0;
}

.material-popup-content .selection-box-points .prompt .material-swatch {
  display: inline-block;
  height: 1em;
  width: 1em;
}

.material-popup-content .materials-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 20em;
}

.material-popup-content .materials-list .material-swatch {
  border-color: white;
}

.material-popup-content .header {
  font-weight: bold;
  padding: 0.5em 0;
}

.profile-tooltip .popup-close,
.material-popup-content .popup-close {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 0;
}

.material-popup-content .popup-close {
  top: -3em;
  right: -3em;
}

.profile-tooltip .popup-close {
  top: -2.25em;
  right: -2.25em;
}

.profile-tooltip .popup-close .ui.button,
.material-popup-content .popup-close .ui.button {
  background: white;
  height: 2.5em;
  box-shadow: 0px 2px 4px 0px rgb(34 36 38 / 12%), 0px 2px 10px 0px rgb(34 36 38 / 15%);
}

.ui.popup.no-padding {
  padding: 0;
}

.profile-setting {
  cursor: pointer;
  padding-bottom: 1.5em;
}

.refinement.ui.popup {
  max-width: 300px;
}

.profile-tooltip .emphasis {
  text-decoration: underline;
}

.profile-tooltip .refinement-options {
  border-bottom: thin solid #eeeeee;
}

.profile-tooltip .refinement-options .text,
.profile-tooltip .refinement-options .item {
  padding: 0.5em 2em 0.5em 1em;
  font-weight: 500;
}

.profile-tooltip .refinement-proportions-container .material-applies-from {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile-tooltip .refinement-proportions-container {
  border-bottom: thin solid #eeeeee;
  padding: 0.5em 2em 0.5em 1em;
}

.profile-tooltip .refinement-proportions {
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5em 0;
}

.profile-tooltip .refinement-proportions .ui.icon.input>input {
  padding-right: 1.85em !important;
}

.profile-tooltip .refinement-proportions .ui.input>input {
  padding-left: 0.25em;
  text-align: right;
}

.profile-tooltip .refinement-proportions-container .mirroring .ui.checkbox {
  padding: 0.5em;
}

.profile-tooltip .action-header {
  padding: 0.78571429em 1.5em calc(0.78571429em / 2);
  color: rgba(0, 0, 0, 0.4);
}

.profile-tooltip .button-list .ui.button,
.profile-tooltip .button-list .ui.button:focus,
.profile-setting .button-list .ui.button {
  background-color: transparent;
  text-align: left;
}

.profile-tooltip .ui.button .sub {
  font-size: 0.75em;
}

@media (pointer: fine) {

  .profile-tooltip .button-list .ui.button:hover,
  .profile-setting .button-list .ui.button:hover {
    background-color: transparent;
  }

}

.profile-tooltip .tip-hover {
  margin-bottom: 0.75em;
  font-size: 0.75em;
  text-align: center;
}

.setting .setting-header,
.profile-setting .profile-setting-header {
  font-weight: bold;
}

.profile-setting .profile-setting-header,
.setting .setting-header.no-divider {
  margin-bottom: 0.5em;
}

.profile-setting .profile-setting-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.setting .next-missing {
  padding-top: 1em;
}

.setting .done-profiles {
  margin-top: 1em;
}

.setting .done-profiles .prompt {
  margin-bottom: 0.5em;
}

.trace-profile-thumbnail {
  position: relative;
}

.trace-profile-thumbnail img {
  max-height: 3em;
  max-width: 15em;
  background-color: #eeeeee;
  border: #555555 solid 2px;
  position: relative;
  padding: 0.25em;
  box-sizing: border-box;
}

.trace-profile-thumbnail.selected img {
  border: $make-and-play-orange solid 2px;
}

.disabled-indication {
  position: absolute;
  top: -0.5em;
  right: -0.5em;
  background-color: white;
}

@media (pointer: fine) {

  .trace-profile-thumbnail img:hover {
    border: $make-and-play-orange solid 2px;
  }

}

.action-header {
  border-bottom: thin solid #cccccc;
}

.action-header.no-border {
  border-bottom: none;
}

.refinement {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.25em;
}

.refinement.current img {
  box-sizing: border-box;
  outline: solid 2px #ED8822;
  outline-offset: -2px;
}

.creations {
  padding: calc($main-header-logo-height + 20px) 5vw 0 5vw;
  overflow: auto;
}

.creations .header {
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.creations .header .prompt {
  font-size: 1.25em;
  padding: 0 1vw;
  line-height: 1.5em;
  color: #555555;
}

.main .step-content .tracemaker-content .side-actions p.prompt {
  font-size: 1.1em;
  padding: 0.5em 1em;
}

.creations .thumbnail-name {
  position: absolute;
  bottom: calc(0.25em + 3px);
  left: 4px;
  width: calc(100% - 8px);
  font-size: 1.5em;
  padding: 0.5em;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  pointer-events: none;
  text-align: center;
  color: white;
}

.examples img,
.creations img,
.examples video,
.creations video {
  min-height: 50px;
  width: 100%;
}

@media (pointer: fine) {
  .creations img:hover {
    opacity: 1;
  }
}

.examples.creations-grid,
.creations .creations-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.examples h4.loading,
.creations h4.loading {
  text-align: center;
  width: 100%;
}

.examples .thumbnail,
.creations .thumbnail {
  width: 100%;
  position: relative;
  padding: 0;
  margin-bottom: 1em;
}

.creations .thumbnail .ui.dropdown {
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  z-index: 10;
}

@media (pointer: fine) {

  .creations .thumbnail .ui.dropdown {
    display: none;
  }

  .creations .thumbnail:hover .ui.dropdown {
    display: block;
  }

}

.creations .thumbnail .ui.dropdown.active {
  display: block;
}

.examples .thumbnail,
.creations .thumbnail {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 1px 1px 5px #aaaaaa;
}

@media (pointer: fine) {

  .examples .thumbnail:hover,
  .creations .thumbnail:hover {
    box-shadow: 1px 1px 5px #555555;
    color: #555555;
  }

}

.examples .thumbnail .name,
.creations .thumbnail .name {
  padding: 0.5em;
  font-size: 1.25em;
}

@media (max-width: 600px) {

  .examples .thumbnail .name {
    padding: 0.5em 5vw;
  }

}

.creations .thumbnail .ui.input input[type="text"] {
  font-size: 1.25em;
  padding: 0.5em;
  border: none;
}

.creations .ui.button {
  margin: 0;
}

@media (min-width: 701px) {

  .examples .thumbnail,
  .creations .thumbnail {
    width: 49%;
  }

}

@media (min-width: 1001px) {

  .examples .thumbnail,
  .creations .thumbnail {
    width: 32%;
  }
}

.profile-tooltip .tip {
  margin-bottom: 0.5em;
}

.ui.button.change-trace {
  height: 1.5em;
  font-size: 1em;
  padding: 0 0 0 1em;
}

.ui.button.material-swatch {
  padding: 0;
  border: none;
  border-radius: 0;
}

.materials {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.material-refinement {
  cursor: move;
}

.material-swatch {
  height: 2.5em;
  width: 2.5em;
  cursor: pointer;
  border: thin solid #aaaaaa;
}

@media (pointer: fine) {

  .material-swatch:hover {
    border: thin solid #ED8822;
  }

}

.materials .material-swatch {
  margin-right: 1em;
  margin-bottom: 1em;
}

.ui.popup.material-override .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.material-button.ui.button {
  padding: 0;
  background: none;
}

.choose-rotations .choose-rotations-option canvas,
.choose-component-kind canvas {
  width: 100%;
}

.choose-rotations .choose-rotations-option {
  margin-right: 0.5em;
}

.choose-component-kind .ui.button.choose-component-kind-option {
  position: relative;
  bottom: 3.5em;
  left: calc(50% - 2em);
}

.choose-rotations .choose-rotations-option .ui.button {
  position: relative;
  bottom: 3.5em;
}

.choose-component-kind .component-kind-previews {
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.model-canvas {
  position: fixed;
  top: $main-header-spacing;
  left: $side-panel-width;
  width: calc(100vw - $side-panel-width);
}

.model-view.settings-collapsed .model-canvas {
  left: $collapsed-side-panel-width;
  width: calc(100% - $collapsed-side-panel-width);
}

.model-view.settings-collapsed .template-note {
  left: $collapsed-side-panel-width;
  width: calc(100% - $collapsed-side-panel-width - 2em)
}

.model-view.readonly .model-canvas {
  left: 0;
  width: 100%;
}

.model-view.no-creation {
  display: none;
}

.model-view .collapse-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  right: 0.5em;
  z-index: 10;
}

.model-view .collapse-control .ui.button {
  margin: 0 0 0 0.25em;
}

.settings {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-top: 2em;
}

.settings .setting {
  padding: 0.5em;
}

.settings .setting .label {
  font-weight: bold;
  padding: 0.25em 0;
}

.base-settings-popup .ui.button {
  margin-top: 0.25em;
  display: inline-block;
}

.ui.dimmer {
  z-index: 2000;
}

.loading-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loading-content div {
  padding: 1em;
}

.main .steps {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #eeeeee;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

.main .steps .step-arrow {
  color: #aaaaaa;
  font-size: 2vmax;
  line-height: 2vmax;
  position: absolute;
  top: 2.5vmax;
  z-index: 10;
  height: 5vmax;
  width: 5vmax;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2.5vmax;
}

.main .steps .step-arrow.one {
  left: calc(25% - 2.5vmax);
}

.main .steps .step-arrow.two {
  left: calc(50% - 2.5vmax);
}

.main .steps .step-arrow.three {
  left: calc(75% - 2.5vmax);
}

.main .steps .step-arrow.connected {
  background-color: white;
}

.main .steps .step {
  padding-top: 3vh;
  width: 25%;
  text-align: center;
  color: #aaaaaa;
  cursor: pointer;
  height: 8vh;
}

.main-header .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  height: calc($main-header-height - 1px);
}

.main-header .actions .icons,
.main-header .actions .ui.button {
  color: $make-and-play-orange;
  background-color: transparent;
  font-size: min(2vw, 1.14285714rem);
}

@media (pointer: fine) {

  .main-header .actions .ui.button:hover {
    color: rgba($make-and-play-orange, 0.6);
  }

}

@media (pointer: fine) {

  .main-header .action:hover {
    background-color: $make-and-play-orange;
    color: white;
  }

}

.action-popup-content p.note {
  font-style: italic;
}

.action-popup-content .ui.button.action {
  background-color: $make-and-play-orange;
  color: white;
}

.thumbnail-actions-content .ui.button,
.thumbnail-actions-content .ui.input {
  margin: 0.25em;
  display: block;
}

.main .readonly-actions {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  padding: 2em auto;
  font-size: 1.25em;
  text-align: center;
  z-index: 11;
}

.main .readonly-actions .content {
  max-width: 800px;
  margin: 0.5em max(2em, 50vw - 400px);
}

.main .readonly-actions .content p {
  margin-bottom: 0.5em;
}

.main .readonly-actions .ui.button.action {
  background-color: $make-and-play-orange;
  color: white;
}

.main .readonly-actions .sign-in-to-edit {
  font-size: 2vmin;
  padding: 0.5em;
}

.main .readonly-actions .sign-in-to-edit a {
  color: $make-and-play-orange;
  text-decoration: underline;
}

.main .steps .step.done {
  color: #1D1F67;
}

.main .steps .step.current {
  color: #1D1F67;
  background-color: white;
}

.main .steps .step .number {
  font-size: max(3vw, 4vh);
  font-weight: normal;
  font-family: 'Chango', cursive;
}

.main .steps .step .number i {
  font-size: max(2vw, 1.5vh);
  padding: 0 1vw;
  position: relative;
  bottom: 0.25em;
  color: #cccccc;
}

.main .steps .step.done .number i {
  color: #00D500;
}

.main .steps .step .description {
  padding-top: 0.5em;
  font-size: 1.5vmax;
}

.main .step-content {
  background-color: white;
}

.main .step-content .section {
  text-align: center;
}

.main .step-content .section.search .hint {
  text-align: left;
  padding-bottom: 1em;
}

.main .step-content .section.search .hint a {
  text-decoration: underline;
}

.main .step-content .section.search .postscript {
  margin-top: 1em;
  padding: 1em;
  font-size: 1.25em;
  text-align: left;
  background-color: rgba(139, 203, 223, 0.3);
  border: thin solid rgb(139, 203, 223);
  border-radius: 0.28571429rem;
}

.main .step-content p.prompt {
  padding: 0 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.5vmax;
}

.main .step-content p.prompt a {
  text-decoration: underline;
  cursor: pointer;
}

.main .choose-image p.demo-video-prompt {
  font-size: 1.25em;
  margin-top: 1em;
}

.intro {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  background-color: white;
}

.intro .intro-columns {
  display: flex;
}

.intro .intro-header {
  width: 100%;
}

.intro .intro-header a {
  font-size: 1.25em;
  color: rgba($make-and-play-orange, 1);
}

.intro .tagline .primary .sub {
  margin-bottom: 0.75em;
}

@media (pointer: fine) {

  .intro .intro-header a:hover {
    color: rgba($make-and-play-orange, 0.9);
    text-decoration: underline;
  }

  .intro .intro-header a:hover i.icon {
    text-decoration: none;
  }

}

@media (min-width: 1001px) {

  .intro .intro-header {
    display: flex;
    flex-direction: row-reverse;
    height: 6vh;
  }

  .intro .intro-header .logo {
    height: 8vh;
    z-index: 1000;
    position: relative;
    top: 1vh;
    right: 3vw;
  }

  .intro .intro-columns {
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-start;
  }

  .intro .intro-columns .intro-column {
    width: min(45vw, 650px);
    min-height: 100vh;
    padding: 0.5em 3vw 10em 3vw;
  }

  .intro .intro-columns .intro-column.video {
    background-color: #eeeeee;
    position: relative;
    width: calc(min(100vw, 1400px) - min(45vw, 650px));
    height: 100%;
    padding: 0 0 10em 0;
  }

  .intro .intro-columns .intro-column.video .header {
    padding: 0.5em 1vw;
  }

  .intro .tagline {
    margin-top: 2em;
    margin-right: 1em;
    line-height: 1.15em;
    font-size: min(5vw, 1.4em);
  }

  .intro .tagline .primary {
    font-size: min(2em, 3vw);
  }

  .intro .intro-columns .intro-column.video .action {
    display: none;
  }

  .intro .intro-columns .intro-column .action {
    position: sticky;
    top: 3em;
    margin-top: 3em;
    padding-bottom: 3em;
    background-color: white;
    z-index: 1;
  }

  .examples.creations-grid,
  .creations .creations-grid {
    padding: 0 1vw 5em 1vw;
  }

  .intro .intro-columns .intro-column.video .contact-info {
    margin: 1em 0;
  }

}

@media (max-width: 1000px) {

  .intro {
    max-width: 600px;
  }

  .intro .intro-header .logo {
    width: auto;
    height: 100%;
    margin: 0 calc(70% - 5vw) 0 5vw;
  }

  .intro .intro-header {
    padding-top: 2vh;
    height: 6vmax;
  }

  .intro .intro-columns {
    flex-direction: column;
    align-items: flex-start;
  }

  .intro .intro-columns .intro-column {
    width: 100%;
    padding: 2.5em 0 0 0;
  }

  .intro .intro-columns .intro-column.video {
    padding: 0 0 2.5em 0;
  }

  .intro .intro-columns .intro-column.video .header {
    padding: 0.5em 2.5vw;
  }

  .intro .logo {
    font-size: 2.5em;
  }

  .intro .tagline {
    margin-top: 0;
    margin-bottom: 2em;
    padding: 0 5vw;
    font-size: min(5vw, 1.25em);
    line-height: 1.15em;
  }

  .intro .tagline .primary {
    font-size: min(7vw, 45px);
  }

  .intro .intro-columns .intro-column:not(.video) .action {
    display: none;
  }

  .intro .intro-columns .intro-column .action {
    margin: 3em 0;
    padding: 0 5vw;
  }

  .intro .intro-columns .intro-column.video .contact-info {
    background-color: #eeeeee;
    margin: 3em 0;
  }

}

.intro .logo {
  margin: 0;
  line-height: 1em;
}

.intro .tagline .primary {
  line-height: 1em;
}

.intro .tagline .item-kinds {
  padding: 1.5em 1em 0 1em;
  line-height: 1.5em;
}

.intro .tagline .item-kinds {
  white-space: nowrap;
}

.intro .intro-column .action {
  margin-left: 0;
  margin-right: 0;
}

.sign-in-container .ui.button,
.intro .intro-column .action .ui.button {
  background-color: rgba($make-and-play-orange, 1);
  color: white;
}

@media (pointer: fine) {

  .intro .intro-column .action .ui.button:hover {
    background-color: rgba($make-and-play-orange, 0.9);
  }

}

.intro .intro-column.video {
  position: relative;
  top: 5px;
}

.choose-image .demo-videos {
  padding-top: 1em;
}

.demo-videos .header {
  font-size: 1.25em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.demo-videos .header.dark {
  color: white;
  background-color: black;
  position: relative;
  top: 1px;
}

.demo-videos .header .nav.ui.button {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.demo-videos .header.dark .nav.ui.button {
  color: white;
}

.demo-videos .header .nav.right.ui.button {
  right: 0;
}

.intro .demo-video-container {
  width: 100%;
}

.intro .demo-video-container .playlist-header {
  display: none;
}

.intro .demo-video {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
}

.main p.demo-video-prompt a {
  text-decoration: underline;
}

.main .demo-videos {
  padding: 1em 0.5em;
}

.main .demo-video-container {
  position: relative;
  width: 100%;
  padding: 0;
}

.main .choose-image .demo-video {
  position: absolute;
  top: 4em;
  left: 2em;
  width: calc(100% - 4em);
  height: calc(100% - 8em);
}

.main .choose-image .choices {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.main .choose-component-kind .choices .choice,
.main .choose-image .choices .choice {
  height: 100%;
  width: 32.5%;
  margin-right: 0.5%;
  background-color: #eeeeee;
  cursor: pointer;
  color: #555555;
  padding: 2em 3em;
  font-size: max(1vw, 1em);
}

.main .choose-component-kind .choices .choice {
  padding: 2em 0;
  width: 100%;
}

.main .choose-component-kind .choices .choice {
  width: calc(100% - 2em);
  margin: 0 1em;
  background-color: #33334C;
  color: white;
}

@media (pointer: fine) {

  .main .choose-component-kind .choices .choice:hover {
    background-color: #55556E;
  }

  .main .choose-image .choices .choice:hover {
    background-color: #555555;
    color: #eeeeee;
  }

}

.main .choose-image .choices .choice.drag {
  border-style: dashed;
  border-width: thin;
  border: thin dashed #aaaaaa;
}

.main .choose-component-kind i,
.main .choose-image .choices .choice i {
  margin: 1em;
}

.main .choose-component-kind .label,
.main .choose-image .choices .choice .label {
  padding: 1em 2em;
}

.main .choose-image .image-crop {
  padding-bottom: 3em;
}

.canvas-setting {
  position: absolute;
  right: 0;
  z-index: 1950;
  padding: 0.5em;
  opacity: 0.5;
}

.canvas-setting.left {
  left: 0;
  right: auto;
}

.canvas-setting.center {
  top: 3em;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
  padding: 0;
  opacity: 1;
}

.canvas-setting.bottom {
  top: unset;
  bottom: 0;
  transform: translate(-50%, 0);
}

@media (pointer: fine) {

  .canvas-setting:hover {
    opacity: 1;
  }

}

.canvas-setting .readonly-name {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.5em 1em;
  font-size: 2.5vmin;
}

.canvas-setting .template-name {
  background-color: rgba($make-and-play-orange, 0.6);
  color: white;
  padding: 0.5em 2em;
  font-size: 2.5vmin;
}

.canvas-setting .template-name a {
  color: white;
}

@media (pointer: fine) {

  .canvas-setting .template-name a:hover {
    text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
    color: rgba(255, 255, 255, 0.8);
  }

}

.canvas-setting .template-name .prompt {
  font-size: 0.75em;
}

.canvas-setting .template-name .prompt .ui.checkbox label {
  color: white;
  font-size: 2vmin;
}

.canvas-setting .template-name .prompt .ui.checkbox {
  padding-bottom: 0.5em;
}

.canvas-setting .template-name a>i {
  position: relative;
  bottom: 0.25em;
}

.template-note .template-done,
.template-note-adder .template-done {
  padding: 0 1em;
}

.template-note-adder .template-done svg,
.template-note .template-done svg {
  height: 2.5em;
  width: 2.5em;
}

.template-note-adder .template-done svg text.label,
.template-note .template-done svg text.label {
  color: $make-and-play-orange;
  font-weight: bold;
}

.ui.popup.recent-versions,
.ui.popup.quality-levels {
  padding: 0;
  max-height: 70vh;
  overflow-y: auto;
}

.quality-levels .list,
.recent-versions .list {
  display: flex;
  flex-direction: column-reverse;
  padding: 0;
  margin: 0;
}

.quality-levels .note {
  padding: 1.5em 1em 1em 1em;
  font-style: italic;
}

.quality-levels .title,
.recent-versions .title {
  padding: 1em 2em 0.5em 2em;
  font-weight: bold;
  text-decoration: underline;
  text-align: left;
}

.quality-levels .list .quality-level,
.recent-versions .list .version {
  padding: 1em 1em 1em 2em;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.quality-levels .list .quality-level .ui.button,
.recent-versions .list .version .ui.button {
  margin-left: 1em;
}

.quality-levels .rating {
  width: 25%;
  text-align: right;
  white-space: nowrap;
}

.quality-levels .rating i.icon {
  margin: -0.1em;
}

.quality-levels .label {
  width: 75%;
  text-align: left;
  padding-left: 1em;
  white-space: nowrap;
}

.ui.button.quality-levels-button {
  padding-left: 1em;
  padding-right: 1em;
}

.ui.button.quality-levels-button:not(.icon)>.icon:not(.button):not(.dropdown) {
  margin: -0.1em;
}

@media (pointer: fine) {

  .recent-versions .list .version:hover {
    background-color: #eeeeee;
  }

}

.quality-levels .list .quality-level.selected,
.recent-versions .list .version.current {
  background-color: #e2efff;
  color: #304661;
}

.recent-versions .list .version.no-date {
  padding-left: 3em;
}

@media (max-width: 800px) {

  .main-header {
    height: $main-header-height;
  }

  .main-header .logo svg {
    top: min(3vh, 3vw, 20px);
  }

  .main .step-content .section {
    margin: 2em 2.5vw;
  }

  .main .choose-image .choices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main .choose-image .choices .choice,
  .main .choose-image .choices .choice .dropzone {
    width: 100%;
    height: 15vh;
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
  }

  .tracemaker-content {
    width: 100vw;
  }

  .choose-component-kind .component-kind-previews {
    flex-direction: column;
  }

  .choose-rotations .choose-rotations-option {
    width: calc(95% - 2em);
    margin: 0 2em;
  }

  .choose-rotations .choose-rotations-option .ui.button {
    position: relative;
    bottom: 3.5em;
    left: calc(50% - 2em);
  }

  .sign-in-container {
    width: 95%;
    padding: 5em 2em;
  }
}

@media (min-width: 801px) {

  .main-header {
    height: max($main-header-variable-height, 55px);
  }

  .main-header .logo svg {
    top: min(4vh, 4vw, 20px);
  }

  .main .step-content .section {
    margin: 2em 5vw;
  }

  .main .choose-image .choices {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .main .choose-image .choices .choice {
    width: 32.5%;
    height: 25vh;
  }

  .tracemaker-content .choose-image,
  .tracemaker-content .choose-component-kind,
  .tracemaker-content .choose-rotations {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .choose-component-kind .component-kind-previews {
    flex-direction: row;
  }

  .choose-component-kind .component-kind-previews .component-kind-preview {
    width: calc(50% - 0.5em);
  }

  .choose-rotations {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .choose-rotations .choose-rotations-option {
    width: calc(50% - 4em);
    margin: 0 1em;
  }

  .choose-rotations .choose-rotations-option .ui.button {
    position: relative;
    bottom: 3.5em;
    left: calc(50% - 2em);
  }

}

.action-popup-content {
  width: 40vw;
}

.live-help {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2001;
  padding: 0;
  color: black;
  opacity: 0.5;
}

.live-help:hover {
  opacity: 1;
}

@media (max-width: 800px) {
  .action-popup-content {
    min-width: 33vw;
    max-width: 50vw;
  }
}


@media (max-width: 500px) {
  .action-popup-content {
    min-width: 33vw;
    max-width: 66vw;
  }
}

@font-face {
  font-family: 'fontello';
  src: url('/font/fontello.eot?18694415');
  src: url('/font/fontello.eot?18694415#iefix') format('embedded-opentype'),
    url('/font/fontello.woff2?18694415') format('woff2'),
    url('/font/fontello.woff?18694415') format('woff'),
    url('/font/fontello.ttf?18694415') format('truetype'),
    url('/font/fontello.svg?18694415#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('/font/fontello.svg?18694415#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-flat-rect:before {
  content: '\e807';
}

/* '' */
.icon-quarter-circle:before {
  content: '\e809';
}

/* '' */
.icon-half-circle:before {
  content: '\e80a';
}

/* '' */

@font-face {
  font-family: 'icomoon';
  src: url('/font/icomoon.eot?cud9l3');
  src: url('/font/icomoon.eot?cud9l3#iefix') format('embedded-opentype'),
    url('/font/icomoon.ttf?cud9l3') format('truetype'),
    url('/font/icomoon.woff?cud9l3') format('woff'),
    url('/font/icomoon.svg?cud9l3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"],
[class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-hexagon:before {
  content: "\e900";
}

@font-face {
  font-family: 'fontawesome6';
  src: url('/font/fa-solid-900.eot');
  src: url('/font/fa-solid-900.eot') format('embedded-opentype'),
    url('/font/fa-solid-900.ttf') format('truetype'),
    url('/font/fa-solid-900.woff') format('woff'),
    url('/font/fa-solid-900.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fontawesome6-"],
[class*=" fontawesome6-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fontawesome6' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fontawesome6-fill:before {
  content: "\f575";
}

.fontawesome6-broom:before {
  content: "\f51a";
}

@font-face {
  font-family: 'iconflow';
  src: url('/font/icon-flow.eot');
  src: url('/font/icon-flow.eot') format('embedded-opentype'),
    url('/font/icon-flow.ttf') format('truetype'),
    url('/font/icon-flow.woff') format('woff'),
    url('/font/icon-flow.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconflow-"],
[class*=" iconflow-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconflow' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconflow-line:before {
  content: "\e900";
}